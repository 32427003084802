import $ from 'jquery'

// modalを閉じる
$('.modal .frame-inner .close-button, .close-button', ).on('click', (e) => {
  $('.modal').fadeOut("fast")
})

$('#campaign .item').on('click', (e) => {
  campaignType = $(e.currentTarget).data('campaign')
  switch (campaignType) {
    case "king-life":
      $('#feb-modal').fadeIn("fast")
      break
    case "may-ninehalf":
      $('#may-ninehalf').fadeIn("fast")
      break
    case "june-jack":
      $('#june-jack').fadeIn("fast")
      break
    case "summer-sp":
      $('#summer-sp').fadeIn("fast")
      break
    case "oct5-event":
      $('#oct5-event').fadeIn("fast")
      break
    case "nov8-event":
      $('#nov8-event').fadeIn("fast")
      break
    default:
      $.ajax({
          url: "/" + campaignType,
          type: "get",
          data: {},
          dataType: 'json'
        }).then((res) => {
          $('#modal-add-class').removeClass()
          $('#modal-title').html(res.title)
          $('#modal-explain').html(res.explain)
          $('#modal-add-class').addClass("explain " + res.explain_class)
          $('#modal-image').attr('src', res.image_path)
          $('#modal-peculiar').html(res.pecliar)

          if (res.is_button_clickable) {
            $('#modal-href').attr("href", res.url)
          } else {
            $('#modal-href').removeAttr("href")
          }
          if (res.is_closed) {
            $('#modal-button').attr('src', "/assets/images/modal/button_end.png")
            $('#modal-href').removeAttr("href")
          } else {
            $('#modal-button').attr('src', res.button_path)
          }
          $('#modal').fadeIn("fast")
        })
        .fail((err, status) => {
          if (err.status === 404) {
            // 特に何もしない
          } else {
            console.log(err)
            console.log(status)
          }
        })
  }
})

// 1月キャンペーン用処理
// tweet注意事項を開く
$('.modal').on('click', '.jan-tweet', (e) => {
  $('#modal').css("display", "none")
  $('#modal-jan-tweet-caution').css("display", "block")
})

// tweet注意事項を閉じる
$('#modal-jan-tweet-caution .back-main-modal').on('click', (e) => {
  $('#modal').css("display", "block")
  $('#modal-jan-tweet-caution').css("display", "none")
})

// 過去のキャンペーン情報を開く
$('#campaign .previous-button .button').on('click', (e) => {
  dataType = $(e.currentTarget).data('previousButton')
  if (dataType == "open") {
    $('#campaign .previous-campaign').fadeOut("fast")
    $('#campaign .previous-button .arrow').css("transform", "translateY(-50%) rotate(0deg)")
    $(e.currentTarget).data("previousButton", "close")
  } else {
    $('#campaign .previous-campaign').fadeIn("fast")
    $('#campaign .previous-button .arrow').css("transform", "translateY(-50%) rotate(90deg)")
    $(e.currentTarget).data("previousButton", "open")
  }

})
